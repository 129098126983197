import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../helpers'
import {setCreditWatchStatus} from '../../../../app/modules/subscriptions/core/_requests'
import {SubsCreditWatchDetailLoading} from '../../../../app/modules/subscriptions/subscriptions-cw-list/components/loading/SubsCreditWatchDetailLoading'

type Props = {
  show: boolean
  handleClose: () => void
  cwData: any
  onDataFromChild: any
  // paymentRequest: PaymentRequest
}

const ApproveCW: React.FC<Props> = ({show, handleClose, cwData, onDataFromChild}) => {
  //console.log('cw data is ', cwData)
  const [bondAmount, setBondAmount] = useState<string>('0')
  const [hasLoading, setHasLoading] = useState<boolean>(false)

  const dismissLocation = () => {
    handleClose()
  }

  const handleSubmit = () => {
    setHasLoading(true)
    if (cwData !== '') {
      let postBody: object = {
        app_id: cwData?.appid,
        org_id: cwData?.orgid,
        hs_contact_id: cwData?.hs_id,
        approved_by: cwData?.approved_by,
        deal_id: cwData?.deal_id,
        bond_amount: bondAmount,
        ts_status: 'APPROVED',
      }
      setCreditWatchStatus(postBody).then((response: any) => {
        setHasLoading(false)
        if (response?.success === true) {
          setHasLoading(false)
          onDataFromChild(response)
          dismissLocation()
          setBondAmount('0')
        } else {
          setHasLoading(false)
          onDataFromChild(response)
          dismissLocation()
          setBondAmount('0')
        }
      })
    } else {
      setHasLoading(false)
      dismissLocation()
      setBondAmount('0')
    }
  }

  const handleBondAmountChange = (event: any) => {
    setBondAmount(event.target.value)
  }

  return (
    <Modal
      className='modal fade'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={show}
      dialogClassName='modal-lg modal-dialog-centered max-width-380'
      aria-hidden='true'
      onHide={dismissLocation}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title'>Approve Application</h5>

          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={dismissLocation}
          >
            <KTIcon iconName='cross' className='fs-2x' />
          </div>
        </div>
        <div className='modal-body'>
          <form className='form w-100' noValidate id='kt_login_signin_form'>
            {/* begin::Heading */}
            {/* <div className=' mb-11'>
              <div className='text-gray-500 fw-semibold fs-6'>Dummy content</div>
            </div> */}
            {/* begin::Heading */}
            {/* begin::Form group */}
            <div className='fv-row mb-8'>
              <label className='form-label fs-6 fw-bolder text-dark'>
                Choose Bond Amount (if required)
              </label>
              <select
                className='form-select form-control'
                value={bondAmount}
                onChange={(e) => handleBondAmountChange(e)}
              >
                <option value='0'>Choose...</option>
                <option value='1000'>AUD 1000.00</option>
                <option value='1250'>AUD 1250.00</option>
                <option value='1500'>AUD 1500.00</option>
                <option value='2000'>AUD 2000.00</option>
              </select>
            </div>
            {/* end::Form group */}
          </form>
        </div>
        {hasLoading && <SubsCreditWatchDetailLoading />}
        <div className='modal-footer'>
          <button type='button' className='btn btn-light-primary' onClick={dismissLocation}>
            Cancel
          </button>
          {hasLoading ? (
            <button id='submit' type='button' className='btn btn-primary'>
              <span className=''>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2' />
              </span>
            </button>
          ) : (
            <button id='submit' type='button' className='btn btn-primary' onClick={handleSubmit}>
              <span className='indicator-label'>Approve</span>
              {/*end::Indicator label*/}
              {/*begin::Indicator progress*/}
            </button>
          )}
        </div>
      </div>
    </Modal>
  )
}

export {ApproveCW}
