/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useLayoutEffect } from 'react';
import { toAbsoluteUrl } from '../../../../helpers';
import { PackageContext } from '../../../../../app/modules/subscriptions/context/SubscriptionContext';
import { NumbertoWord } from '../../../../helpers/UtilityHelpers';

interface IMyProps {
  packages: Pkg
}
interface Pkg {
  packageId: string;
  packageType: string;
  planType: string;
  planCost: string;
  vehicleType: string;
  flexiRentQty: number;
  vehicle: {
    body_type: string;
    color: string;
    fuel_type: string;
    Make: string;
    Model: string;
    registration: string;
    transmission: string;
    vin: string;
    year: string;
    _id: string;
  };
  completed: boolean;
};

const Step4 : React.FC<IMyProps> = (props: IMyProps) => {
  // const { packages } = useContext(PackageContext);
  const { packages: newPackage } = props
  
  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      console.log('This will run after 1 second!');
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div data-kt-stepper-element='content'>
        <div className='w-100 '>
          {/* begin::Heading */}
          <h1 className='fw-bold text-dark mb-3 text-center'>Acknowledge!</h1>
          {/* end::Heading */}

          {/* begin::Description */}
          <div className='text-muted fw-semibold fs-4 text-center'>
            Acknowledge package information before submitting.
          </div>
          {/* end::Description */}

          {/* {packages.map((pack, index) => ( */}
            <div className='mt-6 px-12'>
              <div className='d-flex fv-row'>
                <div className='fw-semibold text-gray-600 min-w-100px'>Package Type</div>
                <div className='fw-bold text-gray-800 ms-8'>{newPackage?.packageType}</div>
              </div>
              <div className='separator separator-dashed my-5'></div>

              {newPackage?.planType && (
                <div className='d-flex fv-row'>
                  <div className='fw-semibold text-gray-600 min-w-100px'>Plan Type</div>
                  <div className='fw-bold text-gray-800 ms-8'>
                    {NumbertoWord(newPackage?.planType)} years plan
                  </div>
                </div>
              )}
              <div className='separator separator-dashed my-5'></div>

              <div className='d-flex fv-row'>
                <div className='fw-semibold text-gray-600 min-w-100px'>Vehicle Type</div>
                <div className='fw-bold text-gray-800 ms-8'>{newPackage?.vehicleType}</div>
              </div>
              <div className='separator separator-dashed my-5'></div>

              <div className='d-flex fv-row'>
                <div className='fw-semibold text-gray-600 min-w-100px'>Vehicle</div>
                <div className='fw-bold text-gray-800 ms-8'>
                  {newPackage?.vehicle.Make} {newPackage?.vehicle.Model}
                </div>
              </div>
              <div className='separator separator-dashed my-5'></div>
            </div>
          {/* ))} */}
        </div>
      </div>
    </>
  );
};

export { Step4 };
