/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState, useContext } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { defaultCreateAppData, ICreateAppData } from './IAppModels'
import { IPackages, IVehicle } from '../../../../app/modules/subscriptions/create/ISubscription'
import { StepperComponent } from '../../../assets/ts/components'
import { KTIcon } from '../../../helpers'
import { Step1 } from './steps/Step1'
import { Step2 } from './steps/Step2'
import { Step3 } from './steps/Step3'
// import {Step4} from './steps/Step4'
import { Step4 } from './steps/Step4'
import { PackageContext } from '../../../../app/modules/subscriptions/context/SubscriptionContext'
import moment from 'moment'
import { useSearchParams } from 'react-router-dom'
import { getSubscriberById } from '../../../../app/modules/subscriptions/core/_requests'
import { Subscriptions } from '../../../../app/modules/subscriptions/core/_models'
import { getVehicleByBody } from '../../../../app/modules/vechicles/vehicle-list/core/_requests'

type Props = {
  show: boolean
  packageList: IPackages[]
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body
const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL

const CreatePackageModal = ({ show, handleClose, packageList }: Props) => {

  const [searchParams] = useSearchParams()
  const subscriptionIdParam = searchParams.get('id')

  const { packages, setPackage } = useContext(PackageContext)
  const [newPackage, setNewPackage] = useState({
    _id:'',
    packageType: '',
    packageId: '',
    planType: '',
    flexiRentQty: 0,
    planCost: '0',
    vehicle: {
      body_type: '',
      color: '',
      fuel_type: '',
      Make: '',
      Model: '',
      registration: '',
      transmission: '',
      vin: '',
      year: '',
      _id: '',
    },
    vehicleType: '',
    completed: false,
  }
  )

  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [data, setData] = useState<ICreateAppData>(defaultCreateAppData)
  const [hasError, setHasError] = useState(false)
  const [vehicleList, setVehicleList] = useState<IVehicle[]>([])
  const [customerDetails, setCustomerDetails] = useState<Subscriptions>()

  const [packageError, setPackageError] = useState('')
  const [packageVehicleTypeError, setPackageVehicleTypeError] = useState('')
  const [packageVehicleError, setPackageVehicleError] = useState('')
  const [stepCount, setStepCount] = useState(0)

  useEffect(() => {
    getSubscriptionData()
  }, [])

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const updateData = (fieldsToUpdate: Partial<ICreateAppData>) => {
    const updatedData = { ...data, ...fieldsToUpdate }
    setData(updatedData)
  }

  const checkAppBasic = (): boolean => {
    if (!data.appBasic.appType) {
      return false
    }
    return true
  }

  const checkAppDataBase = (): boolean => {
    if (!data.appDatabase.databaseName || !data.appDatabase.databaseSolution) {
      return false
    }

    return true
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }
    stepper.current.goPrev()
  }

  const nextStep = async () => {
    setHasError(false)
    if (!stepper.current) {
      return
    }
    setStepCount(stepCount + 1)

    if (stepper.current.getCurrentStepIndex() === 1) {
      if (newPackage.packageType === '') {
        setPackageError('Please select Package Type')
        return
      } else {
        setPackageError('')
      }
      if (newPackage.packageType === 'Rent to Own') {
        if (newPackage.planType === '') {
          setPackageError('Please select Package Plan')
          return
        } else {
          setPackageError('')
        }
      }
      if (newPackage.packageType === 'FlexiRent') {
        if (newPackage.flexiRentQty < 1) {
          setPackageError('Please select vehicle quantity 1 or more')
          return
        }
      }
    }
    if (stepper.current.getCurrentStepIndex() === 2) {
      // await _getVehicleTypes('Ford')
      if (newPackage.vehicleType === '') {
        setPackageVehicleTypeError('Please select Vehicle Type')
        return
      } else {
        setPackageVehicleTypeError('')
      }
    }

    if (stepper.current.getCurrentStepIndex() === 3) {
      if (newPackage.vehicle.Make === '') {
        setPackageVehicleError('Please select Vehicle ')
        return
      } else {
        setPackageVehicleError('')
      }

      // if (!checkAppDataBase()) {
      //   setHasError(true)
      //   return
      // }
    }

    stepper.current.goNext()
  }

  // const _getVehicleTypes = (vtype?: String) => {
  //   if (vtype !== '') {
  //     fetch(`${BACKEND_API_URL}/zoho/get-zoho-vehicle?search=${vtype}`)
  //       .then((response) => response.json())
  //       .then((result) => {
  //         console.log("res",result);
          
  //         setVehicleList(result.data)
  //       })
  //       .catch((error) => console.log('error', error))
  //   } else {
  //     setVehicleList([])
  //   }
  // }

  const handleSelectPackage = (updatedPkg: any) => {
    setNewPackage(updatedPkg)
  }

  const getSubscriptionData = () => {
    getSubscriberById(subscriptionIdParam as string).then(async (response) => {
      setCustomerDetails(response)
      if (response?.packages) {
        const transformedPackages = response.packages.map((item) => ({
          _id: item._id || '',
          packageId: item.package_id || '',
          packageType: item.package_name || '',
          planType: item.plan_name ? item.plan_name.replace(' years', '') : '',
          planCost: '',
          vehicleType: item.vehicletype || '',
          flexiRentQty: 0,
          vehicle: {
            body_type: '',
            color: '',
            fuel_type: '',
            Make: item.vehicle_make || '',
            Model: item.vehicle_model || '',
            registration: '',
            transmission: '',
            vin: '',
            year: '',
            _id: item.vehicle_id || '',
          },
          completed: false,
        }))
        setPackage(transformedPackages)
      }
    })
  }

  const handleSubmit = (arr: any) => {
    handleClose()
    setStepCount(0)
    newPackage.completed = true
    let packPayload = [...packages, newPackage] as any    
    packPayload = packPayload.map((item: any) => ({
      package_id: item.packageId,
      package_name: item.packageType,
      plan_name: item.planType + ' years',
      duration: item.planType,
      vehicletype: item.vehicleType,
      vehicle_id: item.vehicle._id,
      vehicle_make: item.vehicle.Make,
      vehicle_model: item.vehicle.Model,
      flexRentQty: item.flexiRentQty,
    }))
    setPackage([...packages, newPackage])
    handleCreateSubscription(packPayload)
  }

  const handleCreateSubscription = (packPayload: any) => {
    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    var raw = JSON.stringify({
      packages: packPayload,
      advance_information: {
        sales_representative_name: customerDetails?.advance_information?.sales_representative_name,
        sales_representative_code: customerDetails?.advance_information?.sales_representative_code,
        account_coordinator_name: customerDetails?.advance_information?.account_coordinator_name,
        account_coordinator_code: customerDetails?.advance_information?.account_coordinator_code,
      },
      subscription_date: moment(new Date()),
    })
    fetch(
      `${BACKEND_API_URL}/subscriptions/update-subscriptions?subscription_id=${subscriptionIdParam}`,
      {
        method: 'PUT',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      }
    )
      .then((response) => response.json())
      .then((result) => {
        getSubscriptionData()
        // handleShow()
      })
      .catch((error) => {
        // handleShow()
      })
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={handleClose}
      onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        <h2>Configure a package</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTIcon className='fs-1' iconName='cross' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        {/*begin::Stepper */}
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
          {/* begin::Aside*/}
          <div className='d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px'>
            {/* begin::Nav*/}
            <div className='stepper-nav ps-lg-10'>
              {/* begin::Step 1*/}
              <div className='stepper-item current' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>1</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Packages</h3>

                    <div className='stepper-desc'>Please select a package</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 1*/}

              {/* begin::Step 2*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>2</span>
                  </div>
                  {/* begin::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Vehicle Type</h3>

                    <div className='stepper-desc'>Select a vehicle type</div>
                  </div>
                  {/* begin::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 2*/}

              {/* begin::Step 3*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>3</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Vehicle</h3>

                    <div className='stepper-desc'>Select a vehicle</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 3*/}

              {/* begin::Step 5*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>4</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Completed</h3>

                    <div className='stepper-desc'>Review and Submit</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}
              </div>
              {/* end::Step 5*/}
            </div>
            {/* end::Nav*/}
          </div>
          {/* begin::Aside*/}

          {/*begin::Content */}
          <div className='flex-row-fluid py-lg-5 px-lg-15'>
            {/*begin::Form */}
            <form id='kt_modal_create_app_form'>
              <Step1 packageList1={packageList} handleSelectPackage={handleSelectPackage} packages={newPackage} />
              <Step2 data={data} updateData={updateData} hasError={hasError} packages={newPackage} handleSelectPackage={handleSelectPackage} />
              <Step3 vehicleList1={vehicleList} packages={newPackage} handleSelectPackage={handleSelectPackage} />
              <Step4 packages={newPackage} />
              {packageError && (
                <div className='fs-5 fw-semibold mb-4 mt-5 text-danger'>{packageError}</div>
              )}
              {packageVehicleTypeError && (
                <div className='fs-5 fw-semibold mb-4 mt-5 text-danger'>
                  {packageVehicleTypeError}
                </div>
              )}
              {packageVehicleError && (
                <div className='fs-5 fw-semibold mb-4 mt-5 text-danger'>{packageVehicleError}</div>
              )}

              {/*begin::Actions */}
              <div className='d-flex flex-stack pt-10'>
                <div className='me-2'>
                  <button
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                    onClick={prevStep}
                  >
                    <KTIcon iconName='arrow-left' className='fs-3 me-1' /> Previous
                  </button>
                </div>
                <div>
                  <button
                    type='button'
                    className='btn btn-lg btn-primary'
                    data-kt-stepper-action='submit'
                    onClick={handleSubmit}
                  >
                    Submit <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                  </button>

                  <button
                    type='button'
                    className='btn btn-lg btn-primary'
                    data-kt-stepper-action='next'
                    onClick={nextStep}
                  >
                    Next Step <KTIcon iconName='arrow-right' className='fs-3 ms-1 me-0' />
                  </button>
                </div>
              </div>
              {/*end::Actions */}
            </form>
            {/*end::Form */}
          </div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>
    </Modal>,
    modalsRoot
  )
}

export { CreatePackageModal }
