/* eslint-disable jsx-a11y/anchor-is-valid */
import { StepProps } from '../IAppModels'
import { IVehicle } from '../../../../../app/modules/subscriptions/create/ISubscription'
import { useContext, useState } from 'react'
import { PackageContext } from '../../../../../app/modules/subscriptions/context/SubscriptionContext'
import { getVehicles } from '../../../../../app/modules/vechicles/vehicle-list/core/_requests'
interface IMyProps {
  vehicleList1: IVehicle[]
  packages: Object
  handleSelectPackage: (updatedPkg: object) => void
}
const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL

const Step3: React.FC<IMyProps> = (props: IMyProps) => {

  const { packages: newPackage, handleSelectPackage } = props

  const [searchedVehicles, setSearchedVehicles] = useState<IVehicle[]>([])
  const [selected, setSelected] = useState<number>()

  const searchVehicle = async (vName?: any) => {
    await getVehicles(`search=${vName}`).then((result: any) => {
      setSearchedVehicles(result.data)
    })
    // const filteredData = vehicleList.filter((value) => {
    //   const nameMatches = value.Make.toUpperCase().includes(vname)
    //   const modelMatches = value.Model.toUpperCase().includes(vname)

    //   console.log(nameMatches,modelMatches);

    //   return nameMatches || modelMatches
    // })
    // console.log(filteredData);
  }

  const uniqeVehicles = searchedVehicles.filter((value, index, array) => index == array.findIndex(item => item.Make == value.Make));

  const handlePackageVehicle = (vehicle: any, iSelected: number) => {
    setSelected(iSelected)
    handleSelectPackage({ ...newPackage, vehicle: vehicle })
  }

  return (
    <>
      {/*begin::Step 3 */}
      <div className='pb-5' data-kt-stepper-element='content'>
        <div className='w-100'>
          {/*begin::Form Group */}

          <div className='fv-row mb-4'>
            <label className='required fs-5 fw-semibold mb-2'>Search By Make or Model</label>

            <input
              type='text'
              placeholder='Type to search a vehicle name'
              className='form-control form-control-lg form-control-solid'
              name='dbname'
              onChange={(e) => searchVehicle(e.target.value)}
            // value={data.appDatabase.databaseName}
            // onChange={(e) =>
            //   updateData({
            //     appDatabase: {
            //       databaseName: e.target.value,
            //       databaseSolution: data.appDatabase.databaseSolution,
            //     },
            //   })
            // }
            />
            {/* {!data.appDatabase.databaseName && hasError && (
              <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                  Database name is required
                </div>
              </div>
            )} */}
          </div>
          {/*end::Form Group */}

          {/*begin::Form Group */}
          <div className='fv-row'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-4'>
              <span className='required'>Select Vehicle From List</span>

              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Select your app database engine'
              ></i>
            </label>

            {/*begin:Option */}
            <div className='py-5'>
              {/*begin::Suggestions*/}
              <div
                data-kt-search-element='suggestions'
                className={searchedVehicles && searchedVehicles.length > 0 ? 'd-none' : ''}
              >
                {/*begin::Illustration*/}
                <div className='text-center px-4 pt-10'>
                  <img
                    src='/media/illustrations/sketchy-1/4.png'
                    alt=''
                    className='mw-100 mh-200px'
                  />
                </div>
                {/*end::Illustration*/}
              </div>
              {/*end::Suggestions*/}
              {/*begin::Results*/}
              <div
                data-kt-search-element='results'
                className={searchedVehicles && searchedVehicles.length > 0 ? '' : 'd-none'}
              >
                {/*begin::Users*/}
                <div className='mh-300px scroll-y me-n5 pe-5'>
                  {/*begin::User*/}
                  {uniqeVehicles
                    ? uniqeVehicles.map((vData, indexs) => (
                      <div
                        data-bs-dismiss='modal'
                        key={indexs}
                        className={`d-flex align-items-center p-3 rounded-3 border-hover border border-dashed border-gray-300 cursor-pointer mb-1 ${selected === indexs ? 'bg-secondary' : ''
                          }`}
                        data-kt-search-element='customer'
                        role='button'
                        onClick={() => handlePackageVehicle(vData, indexs)}
                      >
                        {/*begin::Avatar*/}
                        {/* <div className='symbol symbol-35px symbol-circle me-5'>
                            <img alt='Pic' src='/media/avatars/300-6.jpg' />
                          </div> */}
                        {/*end::Avatar*/}
                        {/*begin::Info*/}
                        <div className='fw-semibold'>
                          <span className='fs-6 text-gray-800 me-2'>{vData?.Make}</span>
                          <span className='badge badge-light'> {vData?.Model}</span>
                        </div>
                        <div className='fw-semibold'>
                          {/* <span className='fs-6 text-gray-800 me-2'>{vData?.registration}</span> */}
                          {/* <span className='badge badge-light'> {vData?.model}</span> */}
                        </div>
                        {/*end::Info*/}
                      </div>
                    ))
                    : null}
                  {/*end::User*/}
                </div>
                {/*end::Users*/}
              </div>
              {/*end::Results*/}
              {/*begin::Empty*/}
              <div data-kt-search-element='empty' className='text-center d-none'>
                {/*begin::Message*/}
                <div className='fw-semibold py-0 mb-10'>
                  <div className='text-gray-600 fs-3 mb-2'>No users found</div>
                  <div className='text-gray-400 fs-6'>
                    Try to search by username, full name or email...
                  </div>
                </div>
                {/*end::Message*/}
                {/*begin::Illustration*/}
                <div className='text-center px-4'>
                  <img
                    src='/media/illustrations/sketchy-1/9.png'
                    alt='user'
                    className='mw-100 mh-200px'
                  />
                </div>
                {/*end::Illustration*/}
              </div>
              {/*end::Empty*/}
            </div>
            {/* <label className='d-flex align-items-center justify-content-between cursor-pointer mb-6'>
              <span className='d-flex align-items-center me-2'>
                <span className='symbol symbol-50px me-6'>
                  <span className='symbol-label bg-light-success'>
                    <i className='fas fa-database text-success fs-2x'></i>
                  </span>
                </span>

                <span className='d-flex flex-column'>
                  <span className='fw-bolder fs-6'>MySQL</span>
                  <span className='fs-7 text-muted'>Basic MySQL database</span>
                </span>
              </span>

              <span className='form-check form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='databaseSolution'
                  value='MySQL'
                  checked={data.appDatabase.databaseSolution === 'MySQL'}
                  onChange={() =>
                    updateData({
                      appDatabase: {
                        databaseName: data.appDatabase.databaseName,
                        databaseSolution: 'MySQL',
                      },
                    })
                  }
                />
              </span>
            </label> */}
            {/*end::Option */}
          </div>
          {/*end::Form Group */}
        </div>
      </div>
      {/*end::Step 3 */}
    </>
  )
}

export { Step3 }
