import React, {Dispatch, SetStateAction, ChangeEvent, useState} from 'react'
import {verify2FactorAuthentication} from '../../../../app/modules/auth/core/_requests'
import {Modal} from 'react-bootstrap'

type Props = {
  show: boolean
  handleClose: () => void
  twoFaData: any
  onDataFromChild: any
}

const TwoFAValidation: React.FC<Props> = ({show, handleClose, twoFaData, onDataFromChild}) => {
  const [customError, setCustomError] = useState<string | undefined>('')
  const [hasLoading, setHasLoading] = useState<boolean>(false)
  const [authenticationCode, setAuthenticationCode] = useState<number | undefined>()

  // console.log(twoFaData)
  const dismissLocation = () => {
    handleClose()
  }

  const handleAuthenticationCodeChange = (event: any) => {
    setAuthenticationCode(event.target.value)
  }
  const handleSubmit = () => {
    // setHasLoading(true)
    if (authenticationCode !== undefined) {
      verify2FactorAuthentication(
        twoFaData.email,
        authenticationCode,
        twoFaData?.twofa_auth?.secret
      ).then((response: any) => {
        setHasLoading(false)
        console.log(response.data)
        if (response?.data?.success === true) {
          // setHasLoading(false)
          onDataFromChild(response?.data?.data)
          dismissLocation()
        } else {
          setHasLoading(false)
          setCustomError(response)
          // dismissLocation()
        }
      })
    } else {
      setCustomError('Please enter verification code')
    }
  }
  return (
    <Modal className='modal fade' show={true} tabIndex={-1} aria-hidden='true'>
      {/*begin::Modal header*/}
      <div className='modal-dialog modal-dialog-centered mw-650px my-0'>
        {/*begin::Modal content*/}
        <div className='modal-content'>
          {/*begin::Modal header*/}
          <div className='modal-header flex-stack'>
            {/*begin::Title*/}
            <h2>Choose An Authentication Method</h2>
            {/*end::Title*/}
          </div>
          {/*begin::Modal header*/}
          {/*begin::Modal body*/}
          <div className='modal-body scroll-y pt-10 pb-15 px-lg-17'>
            {/*begin::Apps*/}
            <div data-kt-element='apps'>
              {/*begin::Heading*/}
              <h3 className='text-dark fw-bold mb-7'>Authenticator Apps</h3>
              {/*end::Heading*/}
              {/*begin::Description*/}
              <div className='text-gray-500 fw-semibold fs-6 mb-10'>
                Using an authenticator app like
                <a href='https://support.google.com/accounts/answer/1066447?hl=en' target='_blank'>
                  Google Authenticator
                </a>
                ,
                <a href='https://www.microsoft.com/en-us/account/authenticator' target='_blank'>
                  Microsoft Authenticator
                </a>
                ,
                <a href='https://authy.com/download/' target='_blank'>
                  Authy
                </a>
                , or
                <a href='https://support.1password.com/one-time-passwords/' target='_blank'>
                  1Password
                </a>
                , scan the QR code. It will generate a 6 digit code for you to enter below.
                {/*begin::QR code image*/}
                <div className='pt-5 text-center'>
                  <img src={`${twoFaData?.twofa_auth?.qrcode}`} />
                </div>
                {/*end::QR code image*/}
              </div>
              {/*end::Description*/}
              {/*begin::Notice*/}
              {customError ? customError : ''}
              <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed mb-10 p-6'>
                {/*begin::Icon*/}
                {/*begin::Svg Icon | path: icons/duotune/general/gen044.svg*/}
                <span className='svg-icon svg-icon-2tx svg-icon-warning me-4'>
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <rect
                      opacity='0.3'
                      x={2}
                      y={2}
                      width={20}
                      height={20}
                      rx={10}
                      fill='currentColor'
                    />
                    <rect
                      x={11}
                      y={14}
                      width={7}
                      height={2}
                      rx={1}
                      transform='rotate(-90 11 14)'
                      fill='currentColor'
                    />
                    <rect
                      x={11}
                      y={17}
                      width={2}
                      height={2}
                      rx={1}
                      transform='rotate(-90 11 17)'
                      fill='currentColor'
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
                {/*end::Icon*/}
                {/*begin::Wrapper*/}
                <div className='d-flex flex-stack flex-grow-1'>
                  {/*begin::Content*/}
                  <div className='fw-semibold'>
                    <div className='fs-6 text-gray-700'>
                      If you having trouble using the QR code, select manual entry on your app, and
                      enter your username and the code:
                      <div className='fw-bold text-dark pt-2'>{twoFaData?.twofa_auth?.secret}</div>
                    </div>
                  </div>
                  {/*end::Content*/}
                </div>
                {/*end::Wrapper*/}
              </div>
              {/*end::Notice*/}
              {/*begin::Form*/}
              <form data-kt-element='apps-form' className='form' action='#'>
                {/*begin::Input group*/}
                <div className='mb-10 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Enter authentication code'
                    name='code'
                    onChange={(e) => handleAuthenticationCodeChange(e)}
                  />
                </div>
                {/*end::Input group*/}
                {/*begin::Actions*/}
                <div className='d-flex flex-center'>
                  <button
                    type='reset'
                    data-kt-element='apps-cancel'
                    className='btn btn-light me-3'
                    onClick={dismissLocation}
                  >
                    Cancel
                  </button>
                  <button
                    type='submit'
                    data-kt-element='apps-submit'
                    className='btn btn-primary'
                    onClick={handleSubmit}
                  >
                    <span className='indicator-label'>Submit</span>
                    <span className='indicator-progress'>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2' />
                    </span>
                  </button>
                </div>
                {/*end::Actions*/}
              </form>
              {/*end::Form*/}
            </div>
            {/*end::Options*/}
          </div>
          {/*begin::Modal body*/}
        </div>
        {/*end::Modal content*/}
      </div>
      {/*end::Modal header*/}
    </Modal>
  )
}

export {TwoFAValidation}
