import { Dispatch, SetStateAction, createContext, useState, ReactNode } from 'react';

// Define the Packages type
export type Packages = {
  packageId: string;
  packageType: string;
  planType: string;
  planCost: string;
  vehicleType: string;
  flexiRentQty: number;
  vehicle: {
    body_type: string;
    color: string;
    fuel_type: string;
    Make: string;
    Model: string;
    registration: string;
    transmission: string;
    vin: string;
    year: string;
    _id: string;
  };
  _id:any,
  completed: boolean;
};

// Define the PackageContextInterface
export interface PackageContextInterface {
  packages: Packages[];
  setPackage: Dispatch<SetStateAction<Packages[]>>;
}

// Default package data for context initialization
const defaultPackageData: PackageContextInterface = {
  packages: [],
  setPackage: () => {},
};

// Create the context
export const PackageContext = createContext<PackageContextInterface>(defaultPackageData);

type PackageProviderProps = {
  children: ReactNode;
};

// PackageProvider component to provide context values
export default function PackageProvider({ children }: PackageProviderProps) {
  const [packages, setPackage] = useState<Packages[]>([]);

  return (
    <PackageContext.Provider value={{ packages, setPackage }}>
      {children}
    </PackageContext.Provider>
  );
}
