import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../../_metronic/helpers'
import { User, UsersQueryResponse, SubscriptionsQueryResponse, Subscriptions, CWFormData } from './_models'
import { CreditWatch } from '../subscriptions-cw-list/core/_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL
const USER_URL = `${API_URL}/user`
// const GET_USERS_URL = `${API_URL}/users/query`
const GET_USERS_URL = `${BACKEND_API_URL}/subscriptions/get-subscriptions`
// const GET_USERS_URL = `${BACKEND_API_URL}/transactions/get-bp-transactions`
const GET_SUBSCRIBER_URL = `${BACKEND_API_URL}/subscriptions/get-subscription-details`
const UPDATE_CW_STATUS_URL = `${BACKEND_API_URL}/subscriptions/approve-cw-status`
const UPDATE_CW_STATUS_DOC_REQ_URL = `${BACKEND_API_URL}/subscriptions/ts-approved-doc-req`
const Create_Watchlists_checkout = `${BACKEND_API_URL}/subscriptions/submit-credit-watch-checklist`
const Get_Watchlists_Checkout = `${BACKEND_API_URL}/subscriptions/get-credit-watch-checklist`
const Edit_Watchlists_Checkout = `${BACKEND_API_URL}/subscriptions/update-credit-watch-checklist`
const GET_CREDIT_WATCH_APP_DETAILS = `${BACKEND_API_URL}/subscriptions/get-application-details`
const GET_CREDIT_WATCH_FORM_DETAILS = `${BACKEND_API_URL}/subscriptions/get-application`
const UPDATE_CW_STATUS_DECLINED_URL = `${BACKEND_API_URL}/subscriptions/decline-cw-status`
const UPDATE_CW_STATUS_INCOMPLETE_URL = `${BACKEND_API_URL}/subscriptions/incomplete-cw-status`
const UPDATE_CW_STATUS_ACCOUNT_REVIEW_URL = `${BACKEND_API_URL}/subscriptions/account-review-cw-status`
const DOWNLOAD_REPORT = `${BACKEND_API_URL}/subscriptions/download-cw-report`
const UPLOAD_CREDIT_WATCHLIST = `${BACKEND_API_URL}/subscriptions/upload-file-cw`
const SET_DEFAULT_PAYMENT_METHOD = `${BACKEND_API_URL}/subscriptions/set-default-payment-method`
const GET_CW_FILE_UID = `${BACKEND_API_URL}/subscriptions/get-files-uid`
const Delete_Subs_Pkg = `${BACKEND_API_URL}/subscriptions/remove-packages`
const Approve_Subs_Pkg = `${BACKEND_API_URL}/subscriptions/packages/approve`
const GET_COMMENTS_LIST = `${BACKEND_API_URL}/creditWatches/get-all-comment`
const ADD_NEW_COMMENTS = `${BACKEND_API_URL}/creditWatches/create-comment`
// const Check_Approve_Subs_Pkg = `${BACKEND_API_URL}/subscriptions/packages/approved`
const GET_CREDIT_SCORE = `${BACKEND_API_URL}/subscriptions/get-credit-score`

const getSubscriberById = async (subscription_id: string): Promise<Subscriptions | undefined> => {
  return axios
    .get(`${GET_SUBSCRIBER_URL}/?sid=${subscription_id}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

// Update Credit watch status

// const setCreditWatchStatus = async (params: object): Promise<Subscriptions | undefined> => {
//   const response = await axios.get(`${UPDATE_CW_STATUS_URL}?sid=${subscription_id}`)
//   const response_1 = response.data
//   return response_1
// }
const setCWStatusDocReq = async (params: object): Promise<CreditWatch | undefined> => {
  const response = await axios.post(`${UPDATE_CW_STATUS_DOC_REQ_URL}`, params)
  const response_1 = response
  return response_1.data
}

const setCreditWatchStatus = async (params: object): Promise<CreditWatch | undefined> => {
  const response = await axios.post(`${UPDATE_CW_STATUS_URL}`, params)
  const response_1 = response
  return response_1.data
}

const setCreditWatchStatusDeclined = async (params: object): Promise<CreditWatch | undefined> => {
  const response = await axios.post(`${UPDATE_CW_STATUS_DECLINED_URL}`, params)
  const response_1 = response
  return response_1.data
}

const setCreditWatchStatusIncomplete = async (params: object): Promise<CreditWatch | undefined> => {
  const response = await axios.post(`${UPDATE_CW_STATUS_INCOMPLETE_URL}`, params)
  const response_1 = response
  return response_1.data
}

const setCreditWatchStatusAccountReview = async (params: object): Promise<CreditWatch | undefined> => {
  const response = await axios.post(`${UPDATE_CW_STATUS_ACCOUNT_REVIEW_URL}`, params)
  const response_1 = response
  return response_1.data
}

const setDefaultPaymentMethod = async (params: object): Promise<Subscriptions | undefined> => {
  const response = await axios.post(`${SET_DEFAULT_PAYMENT_METHOD}`, params)
  const response_1 = response
  return response_1.data
}

const updateSubscription = (subscription: Subscriptions): Promise<Subscriptions | undefined> => {
  return axios
    .post(`${GET_SUBSCRIBER_URL}/${subscription.id}`, subscription)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const getUsers = (query: string): Promise<SubscriptionsQueryResponse> => {
  return axios
    .get(`${GET_USERS_URL}?${query}`)
    .then((d: AxiosResponse<SubscriptionsQueryResponse>) => d.data)
}

const getUserById = (id: ID): Promise<User | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const createUser = (user: User): Promise<User | undefined> => {
  return axios
    .put(USER_URL, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateUser = (subscription: Subscriptions): Promise<Subscriptions | undefined> => {
  return axios
    .post(`${GET_SUBSCRIBER_URL}/${subscription.id}`, subscription)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => { })
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => { })
}

const addWatchlistCheckout = (subscription: Subscriptions): Promise<Subscriptions | undefined> => {
  return axios
    .post(`${Create_Watchlists_checkout}`, subscription)
    .then((response: AxiosResponse<Subscriptions>) => response.data)
    .catch((error) => {
      console.error('API error:', error)
      throw error
    })
}

const getWatchlistCheckout = (subscription: Subscriptions): Promise<Subscriptions | undefined> => {
  return axios
    .get(`${Get_Watchlists_Checkout}?application_id=${subscription}`)
    .then((response: AxiosResponse<Response<Subscriptions>>) => response.data)
    .then((response: Response<Subscriptions>) => response.data)
}

const downloadWatchlistReport = async (
  appid: string,
  uid: string
): Promise<Subscriptions | undefined> => {
  const response = await axios.get(`${DOWNLOAD_REPORT}?appid=${appid}&uid=${uid}`)
  const response_1 = response.data
  return response_1.data
}

const getCreditWatchApplicationDetails = async (
  app_id: string
): Promise<CreditWatch | undefined> => {
  const response = await axios.get(`${GET_CREDIT_WATCH_APP_DETAILS}?appid=${app_id}`)
  const response_1 = response.data
  return response_1.data
}

const getCWApplicationFormDetails = async (
  app_id: string
): Promise<CWFormData | undefined> => {
  const response = await axios.post(`${GET_CREDIT_WATCH_FORM_DETAILS}?appid=${app_id}`)
  const response_1 = response.data
  return response_1.data
}

const getCreditScore = async (abn: string, acn: string): Promise<CreditWatch | undefined> => {
  const response = await axios.post(`${GET_CREDIT_SCORE}?abn=${abn}&acn=${acn}`)
  const response_1 = response.data
  return response_1.data
}


const editWatchlistCheckout = (
  app_id: string,
  subscription: Subscriptions
): Promise<Subscriptions | undefined> => {
  return axios
    .post(`${Edit_Watchlists_Checkout}?application_id=${app_id}`, subscription)
    .then((response: AxiosResponse<Subscriptions>) => response.data)
    .catch((error) => {
      console.error('API error:', error)
      throw error
    })
}

const watchlistFileUpload = (data: any, appId: Subscriptions): Promise<any | undefined> => {
  return axios
    .post(`${UPLOAD_CREDIT_WATCHLIST}?app_id=${appId}`, data)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}

const getCWFileUid = async (app_id: string): Promise<CreditWatch | undefined> => {
  const response = await axios.get(`${GET_CW_FILE_UID}?appid=${app_id}`)
  const response_1 = response.data
  return response_1.data
}

const deletePkg = (_id: Subscriptions, pkgId: any): Promise<void> => {
  return axios.delete(`${Delete_Subs_Pkg}?subscriptionId=${_id}&packageId=${pkgId}`).then(() => { })
}

const approvePkg = (_id: Subscriptions, pkgId: any): Promise<void> => {
  return axios.put(`${Approve_Subs_Pkg}?subscriptionId=${_id}&packageId=${pkgId}`).then(() => { })
}

const getComments = async (query: string): Promise<UsersQueryResponse> => {
  const d = await axios.get(`${GET_COMMENTS_LIST}?${query}`)  
  return d.data
}

const createComments = (payload: any): Promise<any | undefined> => {
  return axios
    .post(`${ADD_NEW_COMMENTS}`, payload)
    .then((response: AxiosResponse<any>) => response.data)
    .catch((error) => {
      console.error('API error:', error)
      throw error
    })
}

// export {getUsers, deleteUser, deleteSelectedUsers, getUserById, createUser, updateUser}
export {
  getSubscriberById,
  updateSubscription,
  getUsers,
  getUserById,
  updateUser,
  setCreditWatchStatus,
  addWatchlistCheckout,
  getWatchlistCheckout,
  editWatchlistCheckout,
  getCreditWatchApplicationDetails,
  getCWApplicationFormDetails,
  setCreditWatchStatusDeclined,
  setCreditWatchStatusIncomplete,
  downloadWatchlistReport,
  watchlistFileUpload,
  setDefaultPaymentMethod,
  getCWFileUid,
  deletePkg,
  approvePkg,
  setCreditWatchStatusAccountReview,
  getCreditScore,
  getComments,
  createComments,
  setCWStatusDocReq
}
