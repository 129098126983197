import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import Select from 'react-select'
import {KTIcon} from '../../../helpers'
import {setCreditWatchStatusIncomplete} from '../../../../app/modules/subscriptions/core/_requests'
import {SubsCreditWatchDetailLoading} from '../../../../app/modules/subscriptions/subscriptions-cw-list/components/loading/SubsCreditWatchDetailLoading'

type Props = {
  show: boolean
  handleClose: () => void
  cwData: any
  onDataFromChild: any
  // paymentRequest: PaymentRequest
}

const IncompleteCW: React.FC<Props> = ({show, handleClose, cwData, onDataFromChild}) => {
  const [requiredDocs, setRequiredDocs] = useState<any>([])
  const [hasLoading, setHasLoading] = useState<boolean>(false)

  const options = [
    {value: 'Drivers Licence Front', label: 'Drivers Licence Front'},
    {value: 'Drivers Licence Back', label: 'Drivers Licence Back'},
    {value: 'Proof of Residential Address', label: 'Proof of Residential Address'},
    {value: 'Bank Statement', label: 'Bank Statement'},
    {value: 'Driving Record', label: 'Driving Record'},
    {value: 'Secondary Contact', label: 'Secondary Contact'},
    {value: 'Other', label: 'Other'},
  ]

  const dismissLocation = () => {
    handleClose()
  }

  const handleSubmit = () => {
    setHasLoading(true)
    if (cwData !== '') {
      if (requiredDocs.length > 0) {
        let postBody: object = {
          app_id: cwData?.appid,
          org_id: cwData?.orgid,
          hs_contact_id: cwData?.hs_id,
          deal_id: cwData?.deal_id,
          required_documents: requiredDocs,
          ts_status: 'INCOMPLETE',
        }
        setCreditWatchStatusIncomplete(postBody).then((response: any) => {
          setHasLoading(false)
          if (response?.success === true) {
            setHasLoading(false)
            onDataFromChild(response)
            dismissLocation()
            setRequiredDocs([])
          } else {
            setHasLoading(false)
            onDataFromChild(response)
            dismissLocation()
            setRequiredDocs([])
          }
        })
      } else {
        setHasLoading(false)
        dismissLocation()
        setRequiredDocs([])
      }
    } else {
      setHasLoading(false)
      dismissLocation()
      setRequiredDocs([])
    }
  }

  const handleRequiredDocChange = (selectedVal: object) => {
    setRequiredDocs(selectedVal)
  }

  return (
    <Modal
      className='modal fade'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={show}
      dialogClassName='modal-lg modal-dialog-centered max-width-380'
      aria-hidden='true'
      onHide={dismissLocation}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title'>Additional Docs Required</h5>

          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={dismissLocation}
          >
            <KTIcon iconName='cross' className='fs-2x' />
          </div>
        </div>

        <div className='modal-body'>
          <form className='form w-100' noValidate id='kt_login_signin_form'>
            {/* begin::Heading */}
            {/* <div className=' mb-11'>
              <div className='text-gray-500 fw-semibold fs-6'>Dummy content</div>
            </div> */}
            {/* begin::Heading */}
            {/* begin::Form group */}
            <div className='fv-row mb-8'>
              <label className='form-label fs-6 fw-bolder text-dark'>
                Document Request by Account*
              </label>
              <Select
                isMulti={true}
                options={options}
                value={requiredDocs}
                onChange={handleRequiredDocChange}
                // value={requiredDocs}
                // onChange={(e) => handleRequiredDocChange(e)}
              />
            </div>
            {/* end::Form group */}
          </form>
        </div>

        {hasLoading && <SubsCreditWatchDetailLoading />}

        <div className='modal-footer'>
          <button type='button' className='btn btn-light-primary' onClick={dismissLocation}>
            Cancel
          </button>
          {hasLoading ? (
            <button id='submit' type='button' className='btn btn-primary'>
              <span className=''>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2' />
              </span>
            </button>
          ) : (
            <button id='submit' type='button' className='btn btn-primary' onClick={handleSubmit}>
              <span className='indicator-label'>Submit</span>
              {/*end::Indicator label*/}
              {/*begin::Indicator progress*/}
            </button>
          )}
        </div>
      </div>
    </Modal>
  )
}

export {IncompleteCW}
