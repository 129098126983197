/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {/* <SidebarMenuItem to='/builder' icon='switch' title='Layout Builder' fontIcon='bi-layers' /> */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Business</span>
        </div>
      </div>
      {/* <SidebarMenuItem
        to='/apps/user-management/users'
        icon='abstract-28'
        title='Users'
        fontIcon='bi-layers'
      /> */}
      <SidebarMenuItemWithSub
        to='/user-management/customers'
        title='Customers'
        icon='profile-circle'
        fontIcon='bi-person'
      >
        <SidebarMenuItem
          to='/customer-management/customers'
          title='All Contacts'
          hasBullet={true}
        />
        <SidebarMenuItem to='/customer-management/companies' title='Companies' hasBullet={true} />
        {/*<SidebarMenuItem to='/crafted/account/overview' title='Activity Feeds' hasBullet={true} />*/}
        {/* <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} /> */}
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='#' title='Vehicles' icon='car' fontIcon='bi-car'>
        <SidebarMenuItem to='/vehicles/vehicles' title='Vehicles' hasBullet={true} />
        <SidebarMenuItem to='/vehicles/vehicle-types' title='Vehicle Types' hasBullet={true} />
        <SidebarMenuItem
          to='/vehicles/parts-and-accessories'
          title='Parts and Accessories'
          hasBullet={true}
        />

        {/* <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} /> */}
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='#' title='Services' icon='dollar' fontIcon='bi-package'>
        <SidebarMenuItem to='/subscriptions/packages' title='Packages' hasBullet={true} />
        {/* <SidebarMenuItem
          to='/subscriptions/pending-application-review'
          title='Create Rental Package'
          hasBullet={true}
        /> */}

        {/* please change below to pending application link */}
        <SidebarMenuItem
          to='/subscriptions/pending-applications'
          title='Pending Applications'
          hasBullet={true}
        />
        <SidebarMenuItem to='/subscriptions/active' title='Active Rentals' hasBullet={true} />
        <SidebarMenuItem to='/subscriptions/closed' title='Closed Rentals' hasBullet={true} />
        {/* <SidebarMenuItem to='/subscriptions/credit-watch' title='Credit Watch' hasBullet={true} /> */}
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='#'
        title='Rental Applications'
        icon='dollar'
        fontIcon='bi-package'
      >
        <SidebarMenuItem
          to='/subscriptions/credit-watch'
          title='All Applications'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/subscriptions/cw-sales'
          title='Completed Applications'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/subscriptions/cw-accounts'
          title='Applications Under Assessments'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/subscriptions/cw-approved'
          title='Approved Applications'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/subscriptions/cw-declined'
          title='Declined Applications'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/subscriptions/cw-additionaldocrequired'
          title='Additional Doc Req'
          hasBullet={true}
        />
        <SidebarMenuItem to='/subscriptions/cw-all-rentals' title='All Rentals' hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub to='#' title='Payments' icon='paypal' fontIcon='bi-package'>
        {/* <SidebarMenuItem to='/transactions/cards' title='Cards' hasBullet={true} /> */}
        <SidebarMenuItem
          to='/transactions/transactions'
          title='Todays Transactions'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='#' title='Accounts' icon='menu' fontIcon='bi-package'>
        <SidebarMenuItem to='/invoices/invoices' title='Invoices' hasBullet={true} />
      </SidebarMenuItemWithSub>
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub> */}

      <SidebarMenuItemWithSub
        to='#'
        title='Settings'
        icon='setting-3'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/apps/user-management/users' title='Users' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
        <SidebarMenuItem to='/packages/categories' title='Packages' hasBullet={true} />
        <SidebarMenuItem to='/vehicle/package' title='Vehicle Packages' hasBullet={true} />

      </SidebarMenuItemWithSub>
      {/* <SidebarMenuItemWithSub to='/error' title='Errors' fontIcon='bi-sticky' icon='cross-circle'>
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      {/* <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='element-7'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='message-text-2'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub>
      {/* <SidebarMenuItem
        to='/apps/user-management/users'
        icon='abstract-28'
        title='User management'
        fontIcon='bi-layers'
      /> */}
      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTIcon iconName='code' className='fs-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div>
    </>
  )
}

export {SidebarMenuMain}
