import {FC} from 'react'

type Props = {
  values?: string
}

const TransactionStatusCell: FC<Props> = ({values}) =>
  values === 'Approved' ? (
    <span className='badge badge-light-primary'>{values}</span>
  ) : values === 'Insufficient Funds' ? (
    <span className='badge badge-light-warning'>{values}</span>
  ) : (
    <span className='badge badge-light-danger'>{values}</span>
  )

export {TransactionStatusCell}
