import {FC} from 'react'
import {DateFormat} from '../../../../../../_metronic/helpers/UtilityHelpers'
import moment from 'moment'

type Props = {
  joineddate?: string
}

const TransactionJoinedDateCell: FC<Props> = ({joineddate}) => {
  return <div className='text-gray-800'>{moment(joineddate).format('MMMM Do, YYYY')}</div>
}

export {TransactionJoinedDateCell}
