/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'

type Props = {
  className: string
  items?: number
}

const ListsWidget10: React.FC<Props> = ({className, items = 3}) => {
  return (
    <div className='card card-xl-stretch mb-xl-8'>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold text-dark'>Account Overview</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Recent Account Statisticts</span>
        </h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button>
          <Dropdown1 />
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-5'>
        <div className='row gy-5 g-xl-8 mb-5'>
          <div className='col-xl-6'>
            <div className='min-w-125px px-4 me-6 '>
              <div className='fw-semibold fs-6 text-gray-500'>Daily Collections</div>
              <div className='d-flex align-items-center'>
                <div
                  className='fs-2 fw-bold counted'
                  data-kt-countup='true'
                  data-kt-countup-value='4500'
                  data-kt-countup-prefix='$'
                  data-kt-initialized='1'
                >
                  $650
                </div>
                <i className='ki-duotone ki-arrow-up fs-3 text-success ms-2'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
              </div>
            </div>
          </div>
          <div className='col-xl-6'>
            <div className='min-w-125px px-4 me-6 '>
              <div className='fw-semibold fs-6 text-gray-500'>MTD Collections</div>
              <div className='d-flex align-items-center'>
                <div
                  className='fs-2 fw-bold counted'
                  data-kt-countup='true'
                  data-kt-countup-value='4500'
                  data-kt-countup-prefix='$'
                  data-kt-initialized='1'
                >
                  $233,600
                </div>
                {/* <i className='ki-duotone ki-arrow-up fs-3 text-success ms-2'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i> */}
              </div>
            </div>
          </div>
          <div className='col-xl-6'>
            <div className='min-w-125px px-4 me-6 '>
              <div className='fw-semibold fs-6 text-gray-500'>MTD Revenue</div>
              <div className='d-flex align-items-center'>
                <div
                  className='fs-2 fw-bold counted'
                  data-kt-countup='true'
                  data-kt-countup-value='4500'
                  data-kt-countup-prefix='$'
                  data-kt-initialized='1'
                >
                  $29,004
                </div>
                {/* <i className='ki-duotone ki-arrow-up fs-3 text-success ms-2'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i> */}
              </div>
            </div>
          </div>
          <div className='col-xl-6'>
            <div className='min-w-125px px-4 me-6 '>
              <div className='fw-semibold fs-6 text-gray-500'>MTD Bad Debt Expenses</div>
              <div className='d-flex align-items-center'>
                <div
                  className='fs-2 fw-bold counted'
                  data-kt-countup='true'
                  data-kt-countup-value='4500'
                  data-kt-countup-prefix='$'
                  data-kt-initialized='1'
                >
                  $1,480,00
                </div>
                <i className='ki-duotone ki-arrow-up fs-3 text-success ms-2'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ListsWidget10}
