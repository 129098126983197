import {useListView} from '../../core/ListViewProvider'
import {TransactionListToolbar} from './TransactionListToolbar'
import {TransactionListGrouping} from './TransactionListGrouping'
import {TransactionListSearchComponent} from './TransactionListSearchComponent'

const TransactionListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <TransactionListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <TransactionListGrouping /> : <TransactionListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {TransactionListHeader}
