// @ts-nocheck
import {Column} from 'react-table'
import {TransactionInfoCell} from './TransactionInfoCell'
import {TransactionActionsCell} from './TransactionActionsCell'
import {TransactionSelectionCell} from './TransactionSelectionCell'
import {TransactionCustomHeader} from './TransactionCustomHeader'
import {TransactionSelectionHeader} from './TransactionSelectionHeader'
import {TransactionPhoneCell} from './TransactionPhoneCell'
import {TransactionStatusCell} from './TransactionStatusCell'
import {TransactionJoinedDateCell} from './TransactionJoinedDateCell'
import {Transaction} from '../../core/_models'

const transactionColumns: ReadonlyArray<Column<Transaction>> = [
  {
    Header: (props) => <TransactionSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <TransactionSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => (
      <TransactionCustomHeader tableProps={props} title='Type' className='min-w-125px' />
    ),
    id: 'type',
    Cell: ({...props}) => (
      <TransactionPhoneCell
        values={
          props.data[props.row.index]?.Action?.charAt(0).toUpperCase() +
          props.data[props.row.index]?.Action?.slice(1)
        }
      />
    ),
  },
  {
    Header: (props) => {
      return (
        <TransactionCustomHeader tableProps={props} title='Receipt No' className='min-w-125px' />
      )
    },
    id: 'receipt_no',
    Cell: ({...props}) => {
      return <TransactionInfoCell invoice={props.data[props.row.index]} />
    },
  },
  {
    Header: (props) => (
      <TransactionCustomHeader tableProps={props} title='Name' className='min-w-125px' />
    ),
    id: 'crn_2',
    Cell: ({...props}) => <TransactionPhoneCell values={props.data[props.row.index]?.Crn2} />,
  },
  {
    Header: (props) => (
      <TransactionCustomHeader tableProps={props} title='Issuer' className='min-w-125px' />
    ),
    id: 'issuer_name',
    Cell: ({...props}) => (
      <TransactionPhoneCell values={props.data[props.row.index]?.CardDetails?.Issuer} />
    ),
  },
  // {
  //   Header: (props) => (
  //     <TransactionCustomHeader tableProps={props} title='Merchant No' className='min-w-125px' />
  //   ),
  //   id: 'merchant_no',
  //   Cell: ({...props}) => (
  //     <TransactionPhoneCell values={props.data[props.row.index]?.MerchantNumber} />
  //   ),
  // },
  {
    Header: (props) => (
      <TransactionCustomHeader tableProps={props} title='Approved' className='min-w-125px' />
    ),
    id: 'approved',
    Cell: ({...props}) => (
      <TransactionStatusCell values={props.data[props.row.index]?.ResponseText} />
    ),
  },
  {
    Header: (props) => (
      <TransactionCustomHeader tableProps={props} title='DV Token' className='min-w-125px' />
    ),
    id: 'card_no',
    Cell: ({...props}) => <TransactionPhoneCell values={props.data[props.row.index]?.DVToken} />,
  },

  // {
  //   Header: (props) => (
  //     <TransactionCustomHeader tableProps={props} title='Currency' className='min-w-125px' />
  //   ),
  //   id: 'currency',
  //   Cell: ({...props}) => <TransactionPhoneCell values={props.data[props.row.index]?.Currency} />,
  // },
  // {
  //   Header: (props) => (
  //     <CustomerCustomHeader tableProps={props} title='Role' className='min-w-125px' />
  //   ),
  //   accessor: 'role',
  // },
  // {
  //   Header: (props) => (
  //     <CustomerCustomHeader tableProps={props} title='Last login' className='min-w-125px' />
  //   ),
  //   id: 'last_login',
  //   Cell: ({...props}) => (
  //     <CustomerLastLoginCell last_login={props.data[props.row.index].last_login} />
  //   ),
  // },
  // {
  //   Header: (props) => (
  //     <CustomerCustomHeader tableProps={props} title='Two steps' className='min-w-125px' />
  //   ),
  //   id: 'two_steps',
  //   Cell: ({...props}) => (
  //     <CustomerTwoStepsCell two_steps={props.data[props.row.index].two_steps} />
  //   ),
  // },
  {
    Header: (props) => (
      <TransactionCustomHeader tableProps={props} title='Amount' className='min-w-125px' />
    ),
    id: 'amount',
    Cell: ({...props}) => (
      <TransactionPhoneCell values={props.data[props.row.index]?.Amount / 100} />
    ),
  },
  {
    Header: (props) => (
      <TransactionCustomHeader tableProps={props} title='Payment Date' className='min-w-125px' />
    ),
    id: 'payment_date',
    Cell: ({...props}) => (
      <TransactionJoinedDateCell joineddate={props.data[props.row.index]?.ProcessedDateTime} />
    ),
  },

  {
    Header: (props) => (
      <TransactionCustomHeader
        tableProps={props}
        title='Actions'
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <TransactionActionsCell id={props.data[props.row.index].id} />,
  },
]

export {transactionColumns}
