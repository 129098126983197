import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../../../_metronic/helpers'
import { User, UsersQueryResponse, VehicleQueryResponse, Vehicle } from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL
const USER_URL = `${API_URL}/user`
// const GET_USERS_URL = `${API_URL}/users/query`
const GET_USERS_URL = `${BACKEND_API_URL}/zoho/get-zoho-vehicle`

const getVehicles = async (query: string): Promise<VehicleQueryResponse> => {
  return axios.get(`${GET_USERS_URL}?${query}`).then((d: AxiosResponse<VehicleQueryResponse>) => d.data)
}

const getVehicleByBody = (body: string): Promise<VehicleQueryResponse | undefined> => {
  return axios
    .get(`${GET_USERS_URL}?bType=${body}`)
    .then((response: AxiosResponse<Response<VehicleQueryResponse>>) => response.data)
    .then((response: Response<VehicleQueryResponse>) => response.data)
}

const getUserById = (id: ID): Promise<User | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const createUser = (user: User): Promise<User | undefined> => {
  return axios
    .put(USER_URL, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateUser = (user: User): Promise<User | undefined> => {
  return axios
    .post(`${USER_URL}/${user.id}`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => { })
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => { })
}

export { getVehicles, deleteUser, deleteSelectedUsers, getUserById, createUser, updateUser, getVehicleByBody }
