/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useState } from 'react'
import { KTIcon } from '../../../../../_metronic/helpers'
import { StepProps } from '../IAppModels'
import { IPackages } from '../../../../../app/modules/subscriptions/create/ISubscription'
import { PackageContext } from '../../../../../app/modules/subscriptions/context/SubscriptionContext'
import { NumbertoWord } from '../../../../helpers/UtilityHelpers'
interface IMyProps {
  packages: Object
  packageList1: IPackages[]
  handleSelectPackage: (updatedPkg : object) => void
}

const Step1: React.FC<IMyProps> = (props: IMyProps) => {

  const { packages, setPackage } = useContext(PackageContext);
  const { packages: newPackage , handleSelectPackage } = props

  const [defaultRadioSelect, setDefaultRadioSelect] = useState(false)

  const [selectedOption, setSelectedOption] = useState('')
  const [flexiQty, setflexiQty] = useState(1)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const result = parseInt(event.target.value, 10);
    setflexiQty(result);
    const updatedPackages = packages.map((pkg) =>
      pkg.packageType === 'FlexiRent' ? { ...pkg, flexiRentQty: result } : pkg
    );
    setPackage(updatedPackages);
  };

  const handleOptionChange = (event?: any) => {
    console.log(event.target.value)
    setSelectedOption(event.target.value)
  }

  const handleReset = () => {
    setSelectedOption('')
  }

  const handlePackageType = (pType: any, id: any) => {
    let typeObj = {
      ...newPackage,
      ...{
        packageType: pType,
        packageId: id,
        planType: pType === 'FlexiRent' ? '3' : '0',
        flexiRentQty: pType === 'FlexiRent' ? flexiQty : 0
      }
    }
    handleSelectPackage(typeObj)
    setDefaultRadioSelect(false)
    handleReset()
  }

  const handlePackagePlan = (plan: any, cost?: any, optionValue?: any) => {
    handleSelectPackage({
      ...newPackage,
      ...{
        planType: plan,
        planCost: cost
      }
    })
    setDefaultRadioSelect(true)
    setSelectedOption(optionValue)
  }

  return (
    <div className='current' data-kt-stepper-element='content'>
      <div className='w-100'>
        {/*begin::Form Group */}
        <div className='fv-row'>
          {/* begin::Label */}
          <label className='d-flex align-items-center fs-5 fw-semibold mb-4'>
            <span className='required'>Package Types</span>

            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Select your app category'
            ></i>
          </label>
          {/* end::Label */}

          <div id='accordionExample'>
            {props.packageList1
              ? props.packageList1.map((packagedata, i) => (
                <div key={i}>
                  <label className='d-flex align-items-center justify-content-between mt-6 cursor-pointer accordion-item'>
                    <span className='d-flex align-items-center me-2'>
                      <span className='symbol symbol-50px me-6'>
                        <span className='symbol-label bg-light-primary'>
                          <KTIcon iconName='package' className='fs-1 text-primary' />
                        </span>
                      </span>

                      <span className='d-flex flex-column'>
                        <span className='fw-bolder fs-6'>{packagedata?.name}</span>
                        <span className='fs-7 text-muted'>Short intro about this package</span>
                      </span>
                    </span>

                    <span
                      className='form-check form-check-custom form-check-solid'
                      id={`headingOne${i}`}
                    >
                      <input
                        className='form-check-input'
                        data-bs-toggle='collapse'
                        data-bs-target={`#collapseOne${i}`}
                        aria-expanded='true'
                        aria-controls={`collapseOne${i}`}
                        type='radio'
                        name='package_duration'
                        onChange={() => handlePackageType(packagedata?.name, packagedata?._id)}
                      />
                    </span>
                  </label>
                  {packagedata?.duration
                    ? packagedata?.duration.map((durationdata, ix) => (
                      <div
                        id={`collapseOne${i}`}
                        className='accordion-collapse collapse ps-6 ms-6'
                        aria-labelledby={`headingOne${i}`}
                        data-bs-parent='#accordionExample'
                        key={ix}
                      >
                        <div className='py-1 accordion-body' key={i}>
                          {/*begin::Header*/}
                          <div className='d-flex flex-stack flex-wrap'>
                            {/*begin::Toggle*/}
                            <div
                              className='d-flex align-items-center collapsible toggle collapsed'
                              data-bs-toggle='collapse'
                              data-bs-target={`#kt_packages${i}`}
                            >
                              <div className='me-3'>
                                <div className='d-flex align-items-center fw-bold'>
                                  {NumbertoWord(durationdata?.year)} Years Plan
                                </div>
                              </div>
                              {/*end::Summary*/}
                            </div>
                            {/*end::Toggle*/}
                            {/*begin::Input*/}
                            <div className='d-flex my-3 ms-9'>
                              {/*begin::Radio*/}
                              <label className='form-check form-check-custom form-check-solid'>
                                <input
                                  className='form-check-input'
                                  type='radio'
                                  name='payment_method'
                                  value={`option${ix}`}
                                  checked={selectedOption === `option${ix}`}
                                  // onChange={handleOptionChange}

                                  onChange={() =>
                                    handlePackagePlan(
                                      durationdata?.year,
                                      durationdata?.cost,
                                      `option${ix}`
                                    )
                                  }
                                // checked={defaultRadioSelect}
                                // checked={defaultRadioSelect}
                                />
                              </label>
                              {/*end::Radio*/}
                            </div>
                            {/*end::Input*/}
                          </div>
                          {/*end::Header*/}
                          {/*begin::Body*/}

                          {/*end::Body*/}
                        </div>
                      </div>
                    ))
                    : null}
                  {packagedata?.name === 'FlexiRent' ? (
                    <div
                      id={`collapseOne${i}`}
                      className='accordion-collapse collapse ps-6 ms-6'
                      aria-labelledby={`headingOne${i}`}
                      data-bs-parent='#accordionExample'
                    >
                      <div className='py-1 accordion-body' key={i}>
                        {/*begin::Header*/}
                        <div className='d-flex flex-stack flex-wrap'>
                          {/*begin::Toggle*/}
                          <div
                            className='d-flex align-items-center collapsible toggle collapsed'
                            data-bs-toggle='collapse'
                            data-bs-target={`#kt_packages${i}`}
                          >
                            <div className='me-3'>
                              <div className='d-flex align-items-center fw-bold  ms-10'>
                                <label className='fw-bold mb-4'>
                                  <span className='required'>Quantity</span>

                                  <input
                                    className='form-control mt-3'
                                    type='number'
                                    min={0}
                                    placeholder='Please enter Qty'
                                    value={flexiQty}
                                    onChange={handleChange}
                                  />
                                </label>
                              </div>
                            </div>
                            {/*end::Summary*/}
                          </div>
                          {/*end::Toggle*/}
                          {/*begin::Input*/}

                          {/*end::Input*/}
                        </div>
                        {/*end::Header*/}
                        {/*begin::Body*/}

                        {/*end::Body*/}
                      </div>
                    </div>
                  ) : null}
                </div>
              ))
              : null}
          </div>
        </div>
        {/*end::Form Group */}
      </div>
    </div>
  )
}

export { Step1 }
