import {ID, Response} from '../../../../../_metronic/helpers'
export type User = {
  id?: ID
  name?: string
  avatar?: string
  email?: string
  position?: string
  role?: string
  last_login?: string
  two_steps?: boolean
  joined_day?: string
  online?: boolean
  initials?: {
    label: string
    state: string
  }
}

// export type Vehicle = {
//   id?: ID
//   make?: string
//   model?: string
//   registration?: string
//   vin?: string
//   body_type?: string
//   fuel_type?: string
//   year?: string
//   color?: string
//   transmission?: string
//   status?: string
//   createdAt?: string
//   updatedAt?: string
//   avatar?: string
//   position?: string
//   role?: string
//   email?: string
//   properties?: {
//     createdate: string
//     email: string
//     firstname: string
//     hs_object_id: string
//     lastmodifieddate: string
//     lastname: string
//   }
//   initials?: {
//     label: string
//     state: string
//   }
// }

export type Transaction = {
  id?: ID
  Action?: string
  Type?: string
  Crn2?: string
  ReceiptNumber?: string
  MerchantNumber?: string
  CardDetails?: {
    MaskedCardNumber?: string
    Issuer?: string
  }
  DVToken?: string
  BankResponseCode?: string
  ResponseText?: string
  Currency?: string
  Amount?: string
  ProcessedDateTime?: string
  avatar?: string
  position?: string
  role?: string
  email?: string
  properties?: {
    createdate: string
    email: string
    firstname: string
    hs_object_id: string
    lastmodifieddate: string
    lastname: string
  }
  initials?: {
    label: string
    state: string
  }
}

export type UsersQueryResponse = Response<Array<User>>
export type TransactionQueryResponse = Response<Array<Transaction>>
export const initialUser: User = {
  avatar: 'avatars/300-6.jpg',
  position: 'Art Director',
  role: 'Administrator',
  name: '',
  email: '',
}
export const initialInvoice: Transaction = {
  avatar: 'avatars/300-6.jpg',
  position: 'Art Director',
  role: 'Administrator',
  email: '',
}
