import {useEffect} from 'react'
import {Navigate} from 'react-router-dom'
import {useAuth} from './core/Auth'
import {logoutUser} from './core/_requests'

export function Logout() {
  const {logout, currentUser} = useAuth()

  useEffect(() => {
    let userId = currentUser?.data._id
    logoutUser(userId).then((res: any) => {
      if (res.statusCode === 200) {
        logout()
        localStorage.clear()
      }
    })
  }, [])

  return <Navigate to='/auth' />
}
