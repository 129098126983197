import React, {Dispatch, SetStateAction, ChangeEvent, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../helpers'
import {createCreditAndAllocation} from '../../../../app/modules/invoices/invoice-list/core/_requests'
import {InvoiceDetailsLoading} from '../../../../app/modules/invoices/invoice-list/components/loading/InvoiceDetailsLoading'
type Props = {
  show: boolean
  handleClose: () => void
  invoiceData: any
  onDataFromChild: any
  // paymentRequest: PaymentRequest
}

interface ICreditReq {
  InvoiceID: any
  ContactID: any
  CreditAmount: any
  // Add other required properties here
}

const AddInvoiceCredits: React.FC<Props> = ({show, handleClose, invoiceData, onDataFromChild}) => {
  console.log('here is invoice data', invoiceData)
  const [amount, setAmount] = useState<string>('')
  const [customError, setCustomError] = useState<string | undefined>('')
  const [hasLoading, setHasLoading] = useState<boolean>(false)
  const dissmissLocation = () => {
    handleClose()
  }

  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  const handleSubmit = () => {
    setHasLoading(true)
    if (amount <= invoiceData.AmountDue && amount !== '') {
      const creditRequest: ICreditReq = {
        InvoiceID: invoiceData.InvoiceID,
        CreditAmount: amount,
        ContactID: invoiceData.Contact.ContactID,
      }
      // console.log(creditRequest)
      createCreditAndAllocation(creditRequest).then((response) => {
        setHasLoading(false)
        // console.log('hello response', response)
        if (response?.success === true) {
          onDataFromChild(response)
        } else {
          onDataFromChild(response)
          // setCustomError(response?.message)
        }
      })
    } else {
      setCustomError('Amount should not be blank or greater than the due amount!!')
    }
  }

  const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAmount(event.target.value)
  }

  return (
    <Modal
      className='modal fade'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={show}
      dialogClassName='modal-lg modal-dialog-centered max-width-380'
      aria-hidden='true'
      onHide={dissmissLocation}
    >
      <div className='modal-content'>
        {hasLoading && <InvoiceDetailsLoading />}
        <div className='modal-header'>
          <h5 className='modal-title'>Add Credit</h5>

          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={dissmissLocation}
          >
            <KTIcon iconName='cross' className='fs-2x' />
          </div>
        </div>

        <div className='modal-body'>
          <form className='form w-100' noValidate id='kt_login_signin_form'>
            {/* begin::Heading */}
            <div className=' mb-11'>
              <div className='text-gray-500 fw-semibold fs-6'>Add credits to the invoice</div>
              <div className='text-dark-500 fw-semibold fs-4 mt-10'>
                Amount Due: {formatter.format(Number(invoiceData?.AmountDue))}
              </div>
            </div>
            {/* begin::Heading */}
            {/* begin::Form group */}
            <div className='fv-row mb-8'>
              <label className='form-label fs-6 fw-bolder text-dark'>Credits</label>
              <input
                placeholder='Please enter credit'
                type='number'
                name='amount'
                className='form-control'
                onChange={handleAmountChange}
                value={amount}
              />
              {customError && <div className='text-danger'>{customError}</div>}
            </div>
            {/* end::Form group */}
          </form>
        </div>
        <div className='modal-footer'>
          <button type='button' className='btn btn-light-primary' onClick={dissmissLocation}>
            Cancel
          </button>
          {hasLoading ? (
            <button id='submit' type='button' className='btn btn-primary'>
              <span className=''>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2' />
              </span>
            </button>
          ) : (
            <button id='submit' type='button' className='btn btn-primary' onClick={handleSubmit}>
              <span className='indicator-label'>Add Credit</span>
              {/*end::Indicator label*/}
              {/*begin::Indicator progress*/}
            </button>
          )}
        </div>
      </div>
    </Modal>
  )
}

export {AddInvoiceCredits}
